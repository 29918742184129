<template>
    <el-dialog title="上传附件" :visible.sync="confitionDialog" width="840px" @close="onCancel" modal-append-to-body>
        <div class="dialog-inside flockNotupload">
            <el-upload
                class="avatar-uploader"
                action="https://user.erlangcha.com/api/file/upload?token=7ce563cc4f49544991de6ed0ac743bbe"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-success="onAcchandleAvatarSuccess"
                :on-remove="onAcchandleRemove"
                :destroy-on-close='true'
                multiple
                :file-list="fileList_acc_img_url"
                >
                <!-- <img v-if="formList.acc_img_url" :src="formList.acc_img_url" class="avatar"> -->
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <div class="el-upload__tip" slot="tip">支持图片，扩展名为：.jpg，.jpeg，.png</div>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible" append-to-body>
                <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="default" @click="onCancel" class="button-Dialog">取 消</el-button>
            <el-button type="primary" @click="onSubmit" class="button-Dialog">提 交</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialogImageUrl: '',//图片地址
            dialogVisible: false,//查看弹窗
            confitionDialog:false,//整体弹窗
            id:'',//id
			fileList_acc_img_url:[],//列表
            formListImg:[],
        };
    },

    mounted() {
        
    },

    methods: {
        //未上传合同弹窗
        onReceive(row){
            this.id = row.id
            this.confitionDialog = true
        },
        //查看图片
        handlePictureCardPreview(file){
            window.open("https://user.erlangcha.com/advances/Advimage?imageurl=" +file.url);
        },
        //附件上传
		onAcchandleAvatarSuccess(res,file,fileList) {
			this.formListImg = this.onImgUrlList(fileList)
			// this.formList.acc_img_url = URL.createObjectURL(file.raw);
		},
		//附件删除
		onAcchandleRemove(file,fileList){
			this.formListImg = this.onImgUrlList(fileList)
			// console.log(this.formList.acc_img_url)
		},
        
        //图片列表循环获取id
		onImgUrlList(List){
			var ImgUrlList = []
			List.forEach((v,k) => {
                if(v.response){
                    var imgData = {
                        file_id:v.response.data.id,
                        file_link:v.response.data.file_url
                    }
                    ImgUrlList.push(imgData)
                }
			});
			return ImgUrlList
		},
        //提交
        onSubmit(){
            var param = {
                corporate_account_id:this.id,
                file_id_list:this.formListImg
            }
            this.$service.post(this.$api.uploadTcaAttachment,param, (res)=> {
                if(res.code == '200'){
                    this.$message({message: "提交成功",type: "success",});
                    this.confitionDialog = false
                    this.$parent.onButtonInquire();
                }
            })
        },
        //取消
		onCancel(){
            this.id = '',
            this.fileList_acc_img_url = [],//列表
            this.formListImg = [],
            this.confitionDialog = false
        }
    },

    

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
.flockNotupload{

}
</style>