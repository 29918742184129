<template>
    <div class="Compiles">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="编辑企业" :visible.sync="zhanghu" width="700px" :closeOnClickModal='false' @close="closeDialog()">
                <div class="chuangjian">编辑企业</div>
                <el-form :model="form">
                    <!-- <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            选择运营官:
                        </div>
                        <el-select v-model="form.operating_id" placeholder="请选择运营官" popper-class='flock-select'>
                            <div v-infinite-scroll="load" style="" class="flock-load">
                                <el-option :label="item.operating_name" :value="item.id" v-for='(item,index) in inputD' :key="index"></el-option>
                            </div>
                        </el-select>
                    </el-form-item> -->

                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            企业名称:
                        </div>
                        <el-input v-model="form.corporate_account_name" autocomplete="off" :placeholder="form.corporate_account_name"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            可垫款金额:
                        </div>
                        <el-input v-model="form.payment_price" autocomplete="off" :placeholder="form.payment_price"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            已使用垫款金额:
                        </div>
                        <el-input v-model="form.use_price" autocomplete="off" :placeholder="form.use_price"></el-input>
                    </el-form-item>

                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="quxiao" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">提 交</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    props: ["msg", "id"],
    data() {
        return {
            zhanghu: true, //操控弹窗展示
            form: {
                //表单信息
                corporate_account_name: "", //企业名称
                payment_price: "", //可用垫款
                use_price: "", //已用垫款
                operating_id: "", //运营官id
                corporate_account_id: "", //企业id
            },
            formLabelWidth: "140px",
            activeName: "", //显示组件
            // 下拉触底数据
            count: 0, //控制下拉触底增加
            // itemcount: 1, //新增群控制值
            //选择运营官下拉触底
            page: 1,
            inputD: [],
        };
    },
    mounted() {
        if (this.msg == Compiles) {
            this.activeName == true;
        }
        this.onceshi();
    },

    methods: {
        // 获取运营官列表
        getTopOperatingList() {
            var param = {
                page: this.page,
            };
            this.$service.get(this.$api.getTopOperatingList, param, (res) => {
                if (res.code == "200") {
                    this.inputD = res.data.data;
                    // console.log(this.inputD);
                }
            });
        },
        //选择运营官触底事件
        load() {
            // console.log(1111);
            this.page += 1;
            var param = {
                page: this.page,
            };
            this.$service.get(this.$api.getTopOperatingList, param, (res) => {
                var data = res.data.data;
                if (data == "") {
                    this.$message({
                        message: "已没有更多数据",
                        type: "warning",
                    });
                    return;
                }
                if (res.code == "200") {
                    var inputD = this.inputD;
                    data.forEach(function (item, index) {
                        inputD.push(item);
                    });
                    this.inputD = inputD;
                }
            });
        },
        onceshi() {
            var parm = {
                top_corporate_account_id: this.id,
            };
            this.$service.get(
                this.$api.getTopCorporateAccountConfig,
                parm,
                (res) => {
                    if (res.code == "200") {
                        // this.form=res.data;
                        // console.log(res.data);
                        this.form.corporate_account_name =
                            res.data.corporate_account_name;
                        this.form.payment_price = res.data.payment_price;
                        this.form.use_price = res.data.use_price;
                        this.form.corporate_account_id = this.id;
                    }
                }
            );
        },
        // 取消
        quxiao() {
            this.$emit("moren");
            this.zhanghu = false;
        },
        //提交
        tijiao() {
            var form = this.form;
            this.$emit("bianji", form);
            this.zhanghu = false;
        },
        closeDialog() {
            this.$emit("moren");
            this.zhanghu = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.Compiles {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 140px;
        display: inline-block;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    //
}
</style>
<style lang="scss">
.Compiles {
    .upload-demo {
        width: 200px;
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
}
</style>