<template>
    <div class="Account">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="账户充值" :visible.sync="zhanghu" @close="closeDialog()" width="560px" :closeOnClickModal='false' prop-class="dialog">
                <!-- <div class="chuangjian">新增账户</div> -->
                <el-form :model="form">
                    <el-button type="primary" @click="onAddmore()" style="margin-left:0;margin-top:20px">添加账户</el-button>
                    <div v-for="(item,key) in form.agent_account_params" :key="key">
                        <el-form-item label="" >
                            <div class="kuai">
                                <span class="xing">* </span>
                                账户ID
                            </div>
                            <el-input v-model="item.agent_account"  type="text" oninput="value=value.replace(/[^\d\.]/g,'')" autocomplete="off" placeholder="请输入账户ID"></el-input>
                            <el-button type="danger" icon="el-icon-delete" circle class="agenDel" @click="form.agent_account_params.splice(key,1)" v-if="form.agent_account_params.length > 1"></el-button>
                        </el-form-item>
                        <el-form-item label="">
                            <div class="kuai">
                                <span class="xing">* </span>
                                账户ID名称
                            </div>
                            <el-input v-model="item.agent_account_name" autocomplete="off" placeholder="请输入账户ID名称"></el-input>
                        </el-form-item>
                        <div class="agenList"></div>
                    </div>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            公户存档
                        </div>
                        <el-input v-model="form.family_archive" autocomplete="off" placeholder="请输入公户存档"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            指定端口id
                        </div>
                        <el-select v-model="jieshou" value-key="id" placeholder="请选择端口" popper-class='flock-select'>
                            <div v-infinite-scroll="load" style="" class="flock-load">
                                <el-option :label="item.advertiser_name" :value="item" v-for='(item,index) in inputD' :key="index"></el-option>
                            </div>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            运营类型
                        </div>
                        <el-radio-group v-model="form.is_operating_type">
                            <el-radio :label="1">自运营</el-radio>
                            <el-radio :label="2">代运营</el-radio>
                            <el-radio :label="3">渠道</el-radio>
                            <el-radio :label="4">W渠道</el-radio>
                            <el-radio :label="5">cid</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
				    <el-button type="default" @click="quxiao" class="button-Dialog">取 消</el-button>
				    <el-button type="primary" @click="tijiao" class="button-Dialog">提 交</el-button>
                    <!-- <el-button @click="quxiao" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">保 存</el-button> -->
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    props: ["msg"],
    data() {
        return {
            zhanghu: true, //操控弹窗展示
            form: {
                family_archive: "",
                account_port_id: "",
                is_operating_type: 1,
                agent_account_params:[
                    {
                        agent_account: "",
                        agent_account_name: "",
                    }
                ]
            },
            formLabelWidth: "140px",
            activeName: "", //显示组件
            page: 1,
            inputD: [],
            jieshou: "",
        };
    },
    mounted() {
        this.getAdvertiserList();
        if (this.msg == Account) {
            this.activeName == true;
        }
    },
    methods: {
        //增加账户
        onAddmore(){
            var agent_account_params = {
                agent_account: "",
                agent_account_name: "",
            }
            this.form.agent_account_params.push(agent_account_params)
        },
        //获取端口
        getAdvertiserList() {
            var param = {
                page: this.page,
            };
            this.$service.get(this.$api.getAdvertiserList, param, (res) => {
                if (res.code == "200") {
                    this.inputD = res.data.data;
                    // console.log(this.inputD);
                }
            });
        },
        //选择运营官触底事件
        load() {
            // console.log(1111);
            this.page += 1;
            var param = {
                page: this.page,
            };
            this.$service.get(this.$api.getAdvertiserList, param, (res) => {
                var data = res.data.data;
                if (data == "") {
                    this.$message({
                        message: "已没有更多数据",
                        type: "warning",
                    });
                    return;
                }
                if (res.code == "200") {
                    var inputD = this.inputD;
                    data.forEach(function (item, index) {
                        inputD.push(item);
                    });
                    this.inputD = inputD;
                }
            });
        },
        quxiao() {
            this.$emit("zhanghu");
            this.zhanghu = false;
        },
        closeDialog() {
            this.$emit("zhanghu");
            this.zhanghu = false;
        },
        tijiao() {
            this.form.advertiser_name = this.jieshou.advertiser_name;
            this.form.account_port_id = this.jieshou.id;
            var data = this.form;
            console.log(data)
            if(data.agent_account_params.length){
                var onif = false
                data.agent_account_params.forEach((v,k) => {
                    if(!v.agent_account){
                        onif = true
                    }if(!v.agent_account_name){
                        onif = true
                    }
                });
                if(onif){
                    this.$message({ message: "账户信息未填写完全", type: "warning" });return;
                }
            }else{
                this.$message({ message: "账户信息未填写完全", type: "warning" });return;
            }
            if (data.family_archive == "") {
                this.$message({ message: "公户存档", type: "warning" });
                return;
            }
            if (data.account_port_id == "") {
                this.$message({ message: "请选择端口ID", type: "warning" });
                return;
            }
            if (data.is_operating_type == "") {
                this.$message({ message: "请选择运营类型", type: "warning" });
                return;
            }
            // console.log(data)
            var pushdata = []
            data.agent_account_params.forEach((v,k) => {
                pushdata.push({
                    agent_account:v.agent_account,
                    agent_account_name:v.agent_account_name,
                    family_archive:data.family_archive,
                    account_port_id:data.account_port_id,
                    is_operating_type:data.is_operating_type,
                    advertiser_name:data.advertiser_name,
                    account_port_id:data.account_port_id
                })
            });
            // console.log(pushdata)
            this.$emit("zhanghu", pushdata);
        },
    },

};
</script>

<style lang="scss" scoped>
.Account {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 140px;
        display: inline-block;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    //
}
</style>
<style lang="scss">
.Account {
    .agenList{
        border-bottom: 1px solid #ededed;
    }
    .agenDel{
        padding: 5px;
        .el-icon-delete{
            color: #fff;
        }
    }
    .el-form-item{
        margin-top: 20px;
    }
    .el-form-item__content{
        display: flex;
        align-items: center;
        .kuai{
            width: 150px;
        }
    }
    .upload-demo {
        width: 200px;
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog {
        width: 600px;
        top: 30px;
    }
}
</style>