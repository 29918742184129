<template>
    <div class="flock">
        <!--  -->
        <div class="head padd20 borbut20">
            <div class="topSearchSou">
                <div class="searchdan">
                    <div class="title">企业名称：</div>
                    <el-input v-model="input" placeholder="请输入企业名称"></el-input>
                </div>
                <el-button type="primary" icon="el-icon-search" @click="onButtonInquire()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button>
            </div>
        </div>
        <!-- 表格  -->
        <div class="tab padd20">
            <div class="duoshao">
                <div class="title">企业列表</div>
                <el-button type="primary" @click="ontrue" class="xinqiye" icon="el-icon-plus">新增企业</el-button>
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
            <el-table ref="filterTable" :data="tableData" style="width: 100%">
                <el-table-column prop="id" label="ID">
                </el-table-column>
                <el-table-column prop="corporate_account_name" label="企业名称"></el-table-column>
                <el-table-column prop="corporate_account_name" label="合同">
                    <template slot-scope="scope">
                        <p v-if="scope.row.is_attachment == 2" class="pact lan" @click="onUploaded(scope.row)">已上传 <i class="el-icon-picture"></i></p>
                        <p v-if="scope.row.is_attachment == 1" class="pact" @click="onNotupload(scope.row)">未上传 <i class="el-icon-upload" style="color:#f78900"></i></p>
                    </template>
                </el-table-column>
                <el-table-column prop="total_price" label="总打款金额(元)">
                </el-table-column>
                <el-table-column prop="total_actual_price" label="实际到账(元)">
                </el-table-column>
                <el-table-column prop="payment_price" label="已使用/可垫款金额(元)"></el-table-column>
                <el-table-column prop="total_score" label="积分"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" class="bianji" size="mini" plain @click="handleEdit(scope.$index, scope.row ,$event) " id="Compiles">编辑</el-button>
                        <el-button type="primary" size="mini" @click="onConfiguraTion(scope.row) " id="Compiles">配置企业</el-button>
                        <!-- <el-popconfirm title="确定要删除本条记录吗" @confirm="onEnter(scope.row)">
                            <el-button size="mini" slot="reference" type="danger">删除</el-button>
                        </el-popconfirm> -->
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="fenye">
                <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
            </div>
        </div>
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="配置企业" :visible.sync="dialogFormVisible" width="600px" custom-class="flockdialog">
                <div class="chuangjian">创建企业</div>
                <el-form :model="form">
                    <!-- <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            选择运营官:
                        </div>
                        <el-select v-model="form.operating_id" placeholder="请选择运营官" @blur="shiqu()" popper-class='flock-select'>
                            <div v-infinite-scroll="load" style="" class="flock-load">
                                <el-option :label="item.operating_name" :value="item.id" v-for='(item,index) in inputD' :key="index"></el-option>
                            </div>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            企业名称
                        </div>
                        <el-input v-model="form.corporate_account_name" autocomplete="off" placeholder="请输入企业名称"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <!-- <span class="xing">* </span> -->
                            可垫款金额
                        </div>
                        <el-input v-model="form.payment_price" autocomplete="off" placeholder="请输入垫款金额"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <!-- <span class="xing">* </span> -->
                            已使用垫款金额
                        </div>
                        <el-input v-model="form.use_price" autocomplete="off" placeholder="请输入已用金额"></el-input>
                    </el-form-item>
                </el-form>
                <!-- 创建群 -->
                <div class="chuangjian">创建群</div>
                <!--  -->

                <div class="chuangqun" v-for='(item,index) in groupList' :key="item.index" :id="'myid'+index">
                    <el-form :model="form">
                        <el-form-item label="" :label-width="formLabelWidth">
                            <div class="kuai">
                                <span class="xing">* </span>
                                群名称
                            </div>
                            <el-input v-model="item.company.company_name" autocomplete="off" placeholder="请输入群名称"></el-input>
                            <el-button type="primary" icon="el-icon-plus" id="Account" @click="addzhanghu($event,index)">增加账户</el-button>
                            <el-button type="danger" @click="dele($event,index)" v-if="groupList.length>1">删除</el-button>
                        </el-form-item>
                    </el-form>
                    <!-- 增加操作显示添加的群 -->
                    <!-- <div class="xszh" v-for='(i,index) in item[item.index]' :key="i.index" :id="'myid'+index"> -->
                    <div class="xszh" v-for='(i,indexs) in item.agent_account' :key="i.indexs" :id="'myid'+indexs">
                        <div>
                            <span class="zhangxinxi">账户信息：</span>
                            <el-button type="danger" icon="el-icon-delete" class="dd" :id="indexs" circle @click="shanchu($event)"></el-button>
                            <span>{{i.agent_account_name}}</span>
                            <span>{{i.agent_account}}</span>
                            <span>{{i.advertiser_name}}</span>
                            <span v-if="i.is_operating_type==1">自运营</span>
                            <span v-if="i.is_operating_type==2">代运营</span>
                            <span v-if="i.is_operating_type==3">自代运营</span>
                        </div>
                    </div>
                </div>

                <!-- 增加群 -->
                <div class="addqun">
                    <!-- <el-button type="primary" icon="el-icon-search" @click="addqun($event)">增加群</el-button> -->
                </div>
                <!--  -->
                <div slot="footer" class="dialog-footer">
                    <el-button @click="quxiao" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">提 交</el-button>
                </div>
            </el-dialog>
        </div>
        <!-- 显示隐藏提示弹窗 -->
        <div>
            <!-- <Operate ref="Operate" :msg="activeName" @xiugai="xiugai" v-if="activeName == 'Operate'"></Operate> -->
            <Account ref="Account" :msg="activeName" @zhanghu="zhanghu" v-if="activeName == 'Account'"></Account>
            <Compiles ref="Compiles" :msg="activeName" :id="Compiles_id" @bianji="bianji" @moren="moren" v-if="activeName == 'Compiles'"></Compiles>
        </div>
        <!-- 企业配置 -->
        <ConfiTionDialog ref="ConfiTionDialog"></ConfiTionDialog>
        <!-- 未上传弹窗 -->
        <FlockNotupload ref="FlockNotupload"></FlockNotupload>
        <!-- 已上传弹窗 -->
        <FlockUploaded ref="FlockUploaded"></FlockUploaded>
    </div>
</template>

<script>
import ConfiTionDialog from "./enterprise/confiTionDialog.vue";
import FlockNotupload from "./enterprise/flockNotupload.vue";//未上传弹窗
import FlockUploaded from "./enterprise/flockUploaded.vue";//已上传弹窗
import Compiles from "./Compiles.vue";
// import Operate from "./Operate.vue";
import Account from "./Account.vue";

export default {
    data() {
        return {
            paramData:{},//表格参数
            input: "", //搜索内容
            tableData: [],
            dialogFormVisible: false, //操控弹窗展示
            form: {
                corporate_account_name: "", //企业名称
                payment_price: "0", //可垫款
                use_price: "0", //已垫款
            },
            formLabelWidth: "140px",
            activeName: "", //控制显示新增运营官与账户
            //    显示新增的账户
            //新增群块
            groupList: [
                {
                    company: {
                        company_name: "",
                    },
                    agent_account: [],
                },
            ],
            // 下拉触底数据
            count: 0, //控制下拉触底增加
            //选择运营官下拉触底
            inputD: [],
            // 分页
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage:1,//表格当前第几页
            page: 1,
            // 表格id
            Compiles_id: "",
            moban: {
                //提交数据末班
                corporate_account: {
                    operating_id: "",
                    corporate_account_name: "",
                    payment_price: "",
                    use_price: "",
                    corporate_account_id: "",
                },
                data: [
                    {
                        company: {
                            company_name: "",
                        },
                        agent_account: [
                            {
                                agent_account: "",
                                agent_account_name: "",
                                family_archive: "",
                                account_port_id: "",
                                is_operating_type: "",
                            },
                        ],
                    },
                ],
            },
            // 增加用户index
            index: "",
        };
    },
    mounted() {
        this.ongetTopCorporateAccountList(); //获取企业列表
        // this.getTopOperatingList(); //获取运营官列表
    },
    components: {
        // Operate,
        Account,
        Compiles,
        ConfiTionDialog,
        FlockNotupload,
        FlockUploaded
    },
    methods: {
        // 未上传
        onNotupload(row){
            this.$refs.FlockNotupload.onReceive(row);
        },
        //已上传
        onUploaded(row){
            this.$refs.FlockUploaded.onReceive(row);
        },
        //配置企业按钮
        onConfiguraTion(row) {
            this.$refs.ConfiTionDialog.onReceive(row);
        },
        // 获取运营官列表
        // getTopOperatingList() {
        //     var param = {
        //         page: this.page,
        //     };
        //     this.$service.get(this.$api.getTopOperatingList, param, (res) => {
        //         if (res.code == "200") {
        //             this.inputD = res.data.data;
        //             console.log(this.inputD);
        //         }
        //     });
        // },
        //选择运营官触底事件
        // load() {
        //     console.log(1111);
        //     this.page += 1;
        //     var param = {
        //         page: this.page,
        //     };
        //     this.$service.get(this.$api.getTopOperatingList, param, (res) => {
        //         var data = res.data.data;
        //         if (data == "") {
        //             this.$message({
        //                 message: "已没有更多数据",
        //                 type: "warning",
        //             });
        //             return;
        //         }
        //         if (res.code == "200") {
        //             var inputD = this.inputD;
        //             data.forEach(function (item, index) {
        //                 inputD.push(item);
        //             });
        //             this.inputD = inputD;
        //         }
        //     });
        // },
        // 获取企业列表
        // ongetTopCorporateAccountList() {
        //     var param = {
        //         page: 1,
        //     };
        //     this.$service.get(
        //         this.$api.getTopCorporateAccountList,
        //         param,
        //         (res) => {
        //             if (res.code == "200") {
        //                 this.tableData = res.data.data;
        //                 this.lvtotal = res.data.total;
        //             }
        //         }
        //     );
        // },

        //搜索触发
        onButtonInquire(){
            this.paramData = {
                corporate_account_name: this.input,
            }
            this.paramPage = 1
            this.ongetTopCorporateAccountList()
        },
        //获取企业列表
        ongetTopCorporateAccountList() {
            var param = this.paramData
            param.page = this.paramPage
            this.$service.get(this.$api.getTopCorporateAccountList,param,(res) => {
                    if (res.code == "200") {
                        this.tableData = res.data.data;
                        this.lvtotal = res.data.total;
                    }
                }
            );
        },
        // 分页
        DialogCurrentChange(val) {
            this.paramPage = val
            this.ongetTopCorporateAccountList()
        },

        // 弹窗显示
        ontrue() {
            this.dialogFormVisible = !this.dialogFormVisible;
        },
        // 下拉失去焦点
        shiqu() {
            this.page = 1;
        },
        // 取消
        quxiao() {
            this.ontrue();
            this.groupList = [
                {
                    company: {
                        company_name: "",
                    },
                    agent_account: [],
                },
            ];
        },
        //提交
        tijiao() {
            var index = this.index;
            var form = this.form;
            var param = this.moban;
            param.corporate_account = form; //运营官垫付余额等信息
            param.data = this.groupList;
            // param.data[0].company.is_operating_type = 1
            // console.log(param,123123)
            param.data[0].agent_account.forEach(function (item, index) {
                delete item.advertiser_name;
            });
            if (param.corporate_account.corporate_account_name == "") {
                this.$message({ message: "请输入企业名称", type: "warning" });
                return;
            }
            if (param.data[0].company.company_name == "") {
                this.$message({ message: "请输入群名称", type: "warning" });
                return;
            }
            if (param.data[0].agent_account.length == "0") {
                this.$message({ message: "请填写账户信息", type: "warning" });
                return;
            }
            this.$service.post(
                this.$api.createTopCorporateAccountConfig,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.$message({
                            message: "提交成功",
                            type: "success",
                        });
                        this.form.operating_id = "";
                        this.form.corporate_account_names = "";
                        this.form.payment_price = "";
                        this.form.use_price = "";
                        this.groupList = [
                            {
                                company: {
                                    company_name: "",
                                },
                                agent_account: [],
                            },
                        ];
                        this.ontrue();
                        this.ongetTopCorporateAccountList();
                    }
                }
            );
        },
        //新增运营官
        // addyunying(e) {
        //     this.activeName = e.currentTarget.id;
        // },
        //子组件修改父组件activeName
        xiugai() {
            this.activeName = "";
        },
        //删除账户
        dele(e, index) {
            var groupList = this.groupList;
            // console.log(groupList);
            // console.log(index);
            groupList.splice(index, 1);
            // console.log(groupList);
            // if (groupList.input == "") {
            //     this.$message({ message: "请输入群名称", type: "warning" });
            //     return;
            // }
        },
        //增加删除用户提示
        //表格编辑
        handleEdit(index, row, e) {
            this.activeName = e.currentTarget.id;
            this.Compiles_id = row.id;
        },
        //编辑内容  回带的参数 fro m
        bianji(from) {
            var param = {
                corporate_account: "",
            };
            param.corporate_account = from;
            this.$service.post(
                this.$api.createTopCorporateAccountConfig,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.$message({
                            message: "提交成功",
                            type: "success",
                        });
                    }
                }
            );
            this.activeName = "";
        },
        moren() {
            this.activeName = "";
        },
        //确认操作
        onEnter(row) {
            // this.$service.get(this.$api.delAdv,prame, (res)=> {
            // 	if(res.code == '200'){
            // 		this.$message({message: '删除成功', type: 'success'})
            // 		this.ongetAdvList(1)
            // 	}
            // })
        },
        //删除增加的账户
        shanchu(e) {
            this.groupList[this.index].agent_account.splice(
                e.currentTarget.id,
                1
            );
        },
        //添加群
        addqun() {
            var xinzeng = this.groupList;
            var data = {
                company: {
                    company_name: "",
                },
                agent_account: [],
            };
            this.groupList.push(data);
            // console.log(this.groupList);
            // xinzeng.forEach(function (item, index) {
            //     console.log(item.input);
            //     // console.log(item.index)
            // });
        },
        //增加账户
        addzhanghu(e, index) {
            this.index = index;
            var groupList = this.groupList[index];
            // console.log(this.groupList);
            var company_name = groupList.company.company_name; //当前企业名称
            if (company_name == "") {
                this.$message({ message: "请输入群名称", type: "warning" });
                return;
            }
            this.activeName = e.currentTarget.id;
        },
        // 增加账户子组件传值
        zhanghu(data) {
            if (data == undefined) {
                this.activeName = "";
            } else {
                console.log(data)
                if(!data.agent_account){
                    data.forEach((v,k) => {
                        this.groupList[this.index].agent_account.push(v);
                    });
                    this.activeName = "";
                }else{
                    this.groupList[this.index].agent_account.push(data);
                    this.activeName = "";
                }
            }
        },
        
    },
};
</script>

<style lang="scss" scoped>
.flock {
    display: flex;
    flex-direction: column; //垂直显示
    .head {
        display: flex;
        // margin-top: 20px;
        justify-content: center;
    }
    .tab {
        cursor: pointer;
    }
    .chuangjian {
        line-height: 40px;
        font-weight: bold;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 140px;
        display: inline-block;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    .fenye {
        text-align: right;
        margin-top: 25px;
        margin-right: 50px;
    }
    // .dd {
    //     margin-left: 120px;
    // }
    .xszh {
        margin-top: 20px;
    }
    .xszh span {
        margin-left: 10px;
        line-height: 30px;
    }
    .xszh button {
        width: 16px;
        background: #ffffff;
        border: #fff;
        height: 16px;
        text-align: center;
    }
    .xszh .zhangxinxi {
        margin-left: 66px;
    }
    .addqun {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}
</style>
<style lang="scss">
.flock {
    .pact{
        cursor: pointer;
        &.lan{
            color: #0075f7;
        }
    }
    .el-table .cell {
        text-align: center !important;
    }
    .el-input {
        width: 300px;
    }
    .el-step__title {
        margin-left: -5px;
    }

    .el-table__column-filter-trigger i {
        color: #0075f7;
        margin-left: 10px;
    }

    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
    .el-dialog__header {
        padding: 20px 20px 20px;
    }

    .el-form-item__label {
        text-align: left;
    }
    .el-form-item__content {
        margin-left: 0 !important;
    }
    .dialog-footer {
        display: flex;
        justify-content: center;
    }
    // .el-dialog {
    //     width: 40%;
    // }
    .el-button--mini,
    .el-button--mini.is-round {
        // margin-left: 15px;
    }
    .el-icon-delete {
        color: red;
    }
    .flock {
        .el-select > .el-input {
            overflow: hidden;
            top: 16px;
        }
    }
    .chuangqun .el-form-item {
        margin-bottom: 0px;
    }
    // .flock-select {
    //     .flock-load {
    //         height: 200px;
    //         width: 100%;
    //         overflow-y: auto;
    //         display: inline-block;
    //     }
    //     .flock-load::-webkit-scrollbar {
    //         width: 5px; /*滚动条宽度*/
    //         height: 10px; /*滚动条高度*/
    //         background: #fff;
    //     }
    //     .flock-load::-webkit-scrollbar-track {
    //         -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //         border-radius: 10px; /*滚动条的背景区域的圆角*/
    //         background-color: #fff; /*滚动条的背景颜色*/
    //     }
    //     .flock-load::-webkit-scrollbar-thumb {
    //         border-radius: 10px; /*滚动条的圆角*/
    //         height: 16px;
    //         -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //         background-color: #409eff; /*滚动条的背景颜色*/
    //     }
    // }
}
</style>